import Link from "next/link";
import React from "react";
import { buildUrlName } from "../../../../lib/generic";
import { BadgeVerification } from "../../../global/badgeVerification/BadgeVerification";
import { BadgeOptions } from "../../../../enums/BadgeOptions";
import { MdPersonOutline } from "react-icons/md";
import useImageSize from "../../../../hooks/global/useImageSize";
import { CustomChip } from "../../../global/chips/CustomChip";
import { MaterialCardScore } from "../../../explore/material/cards/MaterialCardScore";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { IUserData } from "../../../../interfaces/user/IUserData";

interface IHomepageSpecialistCard {
  data: IUserData;
}

export function HomepageSpecialistCard({ data }: IHomepageSpecialistCard) {
  const [resizedImage, setResizedImage] = useImageSize({
    imageUrl: data.coverUrl,
    size: 500,
    isCover: true,
    originalFormat: true,
  });
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );
  const [resizedProfileImage, setResizedProfileImage, usingDefaultImage] =
    useImageSize({
      imageUrl: data.avatarUrl,
      size: 300,
      isProfile: true,
      originalFormat: true,
    });

  return (
    <Link
      scroll={true}
      href={{
        pathname: "/specialist/[id]/[name]",
        query: { id: data.id, name: buildUrlName(data.name) },
      }}
      data-cy="homepage-specialist-card"
      style={{
        backgroundColor: componentColors.surface.surfContainer,
      }}
      className={` w-72 lg:w-80 mx-2 lg:mx-0 flex items-start justify-center rounded-2xl pb-8`}
    >
      <div className={`w-72 lg:w-80 `}>
        <div className="w-full relative">
          <div className=" relative h-36 w-full">
            {resizedImage && (
              <img
                alt={`${data.name} Cover Image | ${
                  data.sports &&
                  data.sports
                    .map((item) => item.title)
                    .join(" | ")
                    .toString()
                } ${
                  data?.organisations?.length > 0
                    ? "| " +
                      data.organisations[data.organisations.length - 1].name
                    : ""
                }`}
                title={`${data.name} Cover Image | ${
                  data.sports &&
                  data.sports
                    .map((item) => item.title)
                    .join(" | ")
                    .toString()
                } ${
                  data?.organisations?.length > 0
                    ? "| " +
                      data.organisations[data.organisations.length - 1].name
                    : ""
                }`}
                className="object-cover w-full h-full rounded-2xl"
                src={resizedImage}
                onError={() => {
                  setResizedImage();
                }}
              />
            )}
            <div className=" rounded-l-2xl absolute bg-gradient-to-r from-black-700 to-transparent  left-0 h-full w-1/3 top-0 opacity-100"></div>

            <div className="absolute left-3 top-3 flex items-center space-x-2">
              <MaterialCardScore score={data.score} />
            </div>
          </div>

          <div className="top-[140px]  left-1/2 flex flex-col items-center justify-center absolute transform -translate-x-1/2 -translate-y-1/2 ">
            <div className="w-28 h-28 relative bg-black-700 rounded-full flex items-center justify-center">
              {resizedProfileImage ? (
                <img
                  loading="lazy"
                  className={`object-cover  ${
                    usingDefaultImage ? "w-16" : "rounded-full w-full h-full "
                  }`}
                  alt={`${data.name} Profile Image | ${
                    data.sports &&
                    data.sports
                      .map((item) => item.title)
                      .join(" | ")
                      .toString()
                  } ${
                    data?.organisations?.length > 0
                      ? "| " +
                        data.organisations[data.organisations.length - 1].name
                      : ""
                  }`}
                  title={`${data.name} Profile Image | ${
                    data.sports &&
                    data.sports
                      .map((item) => item.title)
                      .join(" | ")
                      .toString()
                  } ${
                    data?.organisations?.length > 0
                      ? "| " +
                        data.organisations[data.organisations.length - 1].name
                      : ""
                  }`}
                  src={resizedProfileImage}
                  onError={() => {
                    setResizedProfileImage();
                  }}
                />
              ) : (
                <MdPersonOutline className="w-16 h-16" />
              )}
              {data?.verificationBadge && (
                <div className="right-0 bottom-0 absolute">
                  <BadgeVerification
                    isFromSpecialist
                    type={BadgeOptions[data.verificationBadge]}
                  />
                </div>
              )}
              {data?.verificationBadge && (
                <div className="right-0 bottom-0 absolute">
                  <BadgeVerification
                    isFromSpecialist
                    type={BadgeOptions[data.verificationBadge]}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center flex-col mt-10">
          <p className="text-xl font-medium capitalize">{data.name}</p>
          <div className="flex flex-wrap items-center justify-center space-x-2">
            {data.sports.map((item, i) => {
              if (i > 2) {
                return;
              } else {
                return <CustomChip label={item.title} key={i} />;
              }
            })}
          </div>
        </div>
      </div>
    </Link>
  );
}
