import React from "react";
import { SecondaryButton } from "../../../global/buttons/material/secondaryButton/SecondaryButton";
import { PrimaryButton } from "../../../global/buttons/material/primaryButton/PrimaryButton";
import { cn } from "../../../../helpers/cn";

interface ActionButtonProps extends React.ComponentProps<"button"> {
  variant: "primary" | "secondary";
  startIcon?: JSX.Element;
}

export const ActionButton = ({
  title,
  onClick,
  variant,
  startIcon,
  className,
}: ActionButtonProps) => {
  const defaultClassName =
    "md_2:h-auto md_2:text-lg font-semibold w-40 md_2:w-full py-[0.9rem] md_2:px-[1.8rem] md_2:rounded-4xl";

  if (variant == "primary") {
    return (
      <PrimaryButton
        title={title}
        className={cn(defaultClassName, className)}
        onClick={onClick}
        startIcon={startIcon}
      />
    );
  }

  return (
    <SecondaryButton
      startIcon={startIcon}
      title={title}
      className={cn(defaultClassName, className)}
      onClick={onClick}
    />
  );
};
