import React from "react";
import { LandingHeroImage } from "./LandingHeroImage";

interface LandingHeroImagesMobileProps extends React.ComponentProps<"div"> {
  phone: string;
}

export const LandingHeroImagesMobile = ({
  phone,
}: LandingHeroImagesMobileProps) => {
  return (
    <div className="flex items-center justify-center md_2:hidden relative">
      <LandingHeroImage
        src={phone}
        alt="Coach landing page phone 1 | POD1UM"
        className="mx-auto w-[20rem]"
      />

      <img
        src="/images/stripe-frame.png"
        className="hidden absolute top-1/2 -left-20 z-20 w-[14rem] h-[4rem] object-contain"
        alt="Stripe Frame | POD1UM"
      />
    </div>
  );
};
