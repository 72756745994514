import React, { useRef } from "react";
import { useHeroImagesTranslate } from "../../../../hooks/homepage/useHeroImagesTranslate";
import { LandingHeroImage } from "./LandingHeroImage";

interface LandingHeroImagesWebProps extends React.ComponentProps<"div"> {
  firstPhone: string;
  secondPhone: string;
  showStripeFrame?: boolean;
}

export const LandingHeroImagesWeb = ({
  firstPhone,
  secondPhone,
  showStripeFrame = false,
}: LandingHeroImagesWebProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [translateYPhone] = useHeroImagesTranslate({ ref: containerRef });

  return (
    <div ref={containerRef} className="space-x-10 relative hidden md_2:flex">
      <div className="pt-20">
        <LandingHeroImage
          src={firstPhone}
          alt="Coach landing page phone 2 | POD1UM"
          style={{ transform: `translateY(-${translateYPhone}px)` }}
        />
      </div>

      {showStripeFrame ? (
        <img
          style={{ transform: `translateX(-${translateYPhone / 2}px)` }}
          src="/images/stripe-frame.png"
          className=" hidden md_2:inline absolute top-1/2 -left-20 z-20 w-[14rem] h-[4rem] object-contain"
          alt="Stripe Frame | POD1UM"
        />
      ) : null}

      <div className="pt-5 ">
        <LandingHeroImage
          src={secondPhone}
          alt="Coach landing page phone 1 | POD1UM"
          style={{ transform: `translateY(${translateYPhone}px)` }}
        />
      </div>
    </div>
  );
};
