import React from "react";
import {
  ActionButton,
  ActionButtonContainer,
  LandingHeroContent,
  LandingHeroImagesMobile,
  LandingHeroImagesWeb,
  LandingHeroRoot,
  LandingHeroSubtitle,
  LandingHeroTexts,
  LandingHeroTextsActionsContainer,
  LandingHeroTitle,
} from "../../LandingHeroSection";
import Link from "next/link";
import { useDispatch } from "react-redux";
import { setTabSection } from "../../../../redux/pages/exploreSlice";
import { StoreImage, StoreLink } from "../../../global/MobileStores";

export const AthleteHeroSection = () => {
  const dispatch = useDispatch();

  const handleExplore = () => {
    dispatch(setTabSection(1));
  };

  return (
    <LandingHeroRoot>
      <LandingHeroContent>
        <LandingHeroTextsActionsContainer className="gap-10">
          <LandingHeroTexts className="gap-2 md_2:gap-6">
            <LandingHeroTitle className="leading-[3rem] md_2:leading-[5rem]">
              Optimise Preparation, <br /> Maximize Performance
            </LandingHeroTitle>

            <LandingHeroSubtitle className="text-sm ">
              Connect with the world&apos;s best trainers and access elite
              training plans tailored for you.
            </LandingHeroSubtitle>
          </LandingHeroTexts>

          <ActionButtonContainer>
            <Link href="create-account">
              <ActionButton
                variant="primary"
                title="Sign up Free"
                className="md_2:w-56"
              />
            </Link>

            <Link href="explore">
              <ActionButton
                variant="secondary"
                title="Explore"
                onClick={handleExplore}
                className="md_2:w-56"
              />
            </Link>
          </ActionButtonContainer>
        </LandingHeroTextsActionsContainer>

        <div className="flex flex-row justify-center items-center gap-4 md:justify-start">
          <StoreLink href="https://apps.apple.com/ie/app/pod1um/id1562663063">
            <StoreImage src="/images/apple-white-icon.svg" />
          </StoreLink>

          <StoreLink href="https://play.google.com/store/apps/details?id=app.pod1um">
            <StoreImage src="/images/google-white-icon.svg" />
          </StoreLink>
        </div>
      </LandingHeroContent>

      <LandingHeroImagesWeb
        firstPhone="/images/coach-landing-phone-1.png"
        secondPhone="/images/coach-landing-phone-2.png"
      />

      <LandingHeroImagesMobile phone="/images/coach-landing-phone-1.png" />
    </LandingHeroRoot>
  );
};
