import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Chip } from "@mui/material";
import { ICustomChip } from "../../../interfaces/globalComponents/chips/ICustomChip";

export const CustomChip = ({ label, icon }: ICustomChip) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );

  return (
    <Chip
      sx={{
        backgroundColor: componentColors.secondary.onSecondary,
        color: componentColors.secondary.onSecondaryContainer,
      }}
      label={label}
      icon={icon}
    />
  );
};
