import React from "react";
import { IUserData } from "../../../interfaces/user/IUserData";
import useImageSize from "../../../hooks/global/useImageSize";
import { getLastOrganisation } from "../../../lib/specialist/getLastOrganisation";
import { Typography } from "@mui/material";
import { buildUrlName } from "../../../lib/generic";
import Link from "next/link";
import { BadgeVerification } from "../../global/badgeVerification/BadgeVerification";

interface ILibraryCoach {
  data: IUserData;
}

export const LibraryCoach = ({ data }: ILibraryCoach) => {
  const [resizedImage] = useImageSize({
    isProfile: true,
    size: 500,
    imageUrl: data.avatarUrl,
  });
  const lastOrganisation = getLastOrganisation(data);

  return (
    <Link
      href={{
        pathname: "/specialist/[id]/[name]",
        query: {
          id: data.id,
          name: buildUrlName(data?.name),
        },
      }}
    >
      <div className="flex flex-col items-center justify-center relative space-y-4">
        <div className="w-20 h-20 relative">
          <img
            src={resizedImage}
            alt={`${data.name} | POD1UM`}
            title={`${data.name} | POD1UM`}
            className="rounded-full w-20 h-20"
          />
          {data.verificationBadge && (
            <BadgeVerification
              isFromSpecialist={true}
              type={data.verificationBadge}
              className="absolute bottom-0 right-0 z-20"
            />
          )}
        </div>

        <div className="flex flex-col items-center justify-center">
          <Typography>{data.name}</Typography>
          {lastOrganisation && (
            <Typography className="text-sm">
              {lastOrganisation?.name}
            </Typography>
          )}
        </div>
      </div>
    </Link>
  );
};
