import React from "react";
import { IUserData } from "../../../../interfaces/user/IUserData";
import useImageSize from "../../../../hooks/global/useImageSize";
import { Avatar } from "@mui/material";

interface IMaterialCardCoachAvatar {
  coach: IUserData;
}

export const MaterialCardCoachAvatar = ({
  coach,
}: IMaterialCardCoachAvatar) => {
  const resizedProfileImage = useImageSize({
    imageUrl: coach.avatarUrl,
    size: 500,
    isProfile: true,
    originalFormat: true,
  })[0];

  return (
    <Avatar
      src={resizedProfileImage ? resizedProfileImage : null}
      alt={`Coach Profile Image | ${coach.name} | POD1UM`}
    >
      {coach.name.split(" ")[0]?.split("")[0]}
      {coach.name.split(" ")[1]?.split("")[0]}
    </Avatar>
  );
};
