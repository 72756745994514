import React from "react";
import useImageSize from "../../../../hooks/global/useImageSize";
import { Avatar } from "@mui/material";
import { Collaborator } from "../../../../interfaces/globalComponents/collaborator/Collaborator";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

interface IMaterialCardCollaborator {
  collaborator: Collaborator;
  position: number;
}

export const MaterialCardCollaborator = ({
  collaborator,
  position,
}: IMaterialCardCollaborator) => {
  const resizedProfileImage = useImageSize({
    imageUrl: collaborator.imageUrl,
    size: 500,
    isProfile: true,
    originalFormat: true,
  })[0];
  const colors = useSelector((state: RootState) => state.theme.componentColors);

  return (
    <Avatar
      src={resizedProfileImage ? resizedProfileImage : null}
      alt={`Collaborator | ${collaborator.title} | POD1UM`}
      sx={{
        position: "absolute",
        left: position * 25,
        top: 0,
        border: "1px solid",
        borderColor: colors.outline.outline,
      }}
    >
      {collaborator.title.split(" ")[0]?.split("")[0]}
      {collaborator.title.split(" ")[1]?.split("")[0]}
    </Avatar>
  );
};
