import React from "react";
import { cn } from "../../../../helpers/cn";

type LandingHeroImageProps = React.ComponentProps<"img">;

export const LandingHeroImage = ({
  src,
  alt,
  style,
  className,
}: LandingHeroImageProps) => {
  return (
    <img
      src={src}
      alt={alt}
      className={cn(
        "w-[96%] shadow-phone rounded-[2rem] lg:rounded-[3rem] 2xl:rounded-[4rem]",
        className
      )}
      style={style}
    />
  );
};
